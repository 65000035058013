import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';

import errors from '../assets/json/eror.JSON';
import success from '../assets/json/rekap.JSON';
import poss from '../assets/json/post.JSON';
import putt from '../assets/json/put.JSON';
import dele from '../assets/json/del.JSON';

const Home = () => {

    const [err, setErr] = useState([]);
    const [scs, setScs] = useState([]);
    const [pos, setPos] = useState([]);
    const [pput, setPput] = useState([]);
    const [del, setDel] = useState([]);

    return (
        // <div className='row pembagi'>
        // <div className='col-sm-10 left'>
        <div>
            <h1>Dokumentasi API SmartCity Kota Medan</h1>
            <hr />
            <div>
                <h6 className="caption mt-2 mb-3">
                    API adalah singkatan dari Application Programming Interface. API sendiri merupakan interface yang dapat menghubungkan satu aplikasi dengan aplikasi lainnya.
                    Dengan kata lain, peran API adalah sebagai perantara antar berbagai aplikasi berbeda, baik dalam satu platform yang sama atau pun lintas platform.
                    Web API Dokumentasi ini menyajikan data-data aktual yang bergerak dari seluruh SKPD dan dikelola oleh Pemerintah Kota Medan.
                </h6>
                <h6 className="caption mt-2 mb-3">
                    Data-data API yang disajikan pun beragam berdasarkan kategori lengkap beserta Response API serta request headernya. dalam Restful API terdapat 4 komponen penting yaitu :
                </h6>

                <h3 className='mt-5'>1. URL Design</h3>
                <h6 className="caption mt-2 mb-3">
                    RESTful API diakses menggunakan protokol HTTP. Penamaan dan struktur URL yang konsisten akan menghasilkan API yang baik dan mudah untuk dimengerti developer. URL API biasa disebut endpoint dalam pemanggilannya.
                </h6>

                <h3 className='mt-5'>2. HTTP Verbs</h3>
                <h6 className="caption mt-2 mb-3">
                    Setiap request yang dilakukan terdapat metode yang dipakai agar server mengerti apa yang sedang di request client:
                </h6>

                <h4 className='mt-3'>a. GET</h4>
                <h6 className="caption mt-2 mb-3">
                    GET adalah metode HTTP Request yang paling mudah, metode ini digunakan untuk membaca atau mendapatkan data dari sumber.
                </h6>

                <h4 className='mt-3'>b. POST</h4>
                <h6 className="caption mt-2 mb-3">
                    POST adalah metode HTTP Request yang digunakan untuk membuat data baru dengan menyisipkan data dalam body saat request dilakukan.
                </h6>

                <h4 className='mt-3'>c. PUT</h4>
                <h6 className="caption mt-2 mb-3">
                    PUT adalah metode HTTP Request yang biasanya digunakan untuk melakukan update data resource.
                </h6>

                <h4 className='mt-3'>d. DELETE</h4>
                <h6 className="caption mt-2 mb-3">
                    DELETE adalah metode HTTP Request yang digunakan untuk menghapus suatu data pada resource.
                </h6>

                <h3 className='mt-5'>3. HTTP Response Code</h3>
                <h6 className="caption mt-2 mb-3">
                    HTTP Response Code adalah kode standarisasi dalam menginformasikan hasil request kepada client. Secara umum terdapat 3 kelompok yang biasa kita jumpai pada RESTful API yaitu :
                </h6>

                <h3 className="section mt-5 mb-4">Respond Body</h3>
                <div className="mb-5">
                    <h3>#Success 2XX</h3>
                    <div className="endpoint">
                        <pre className="output">{JSON.stringify(pos, null, 4)}</pre>
                    </div>
                </div>

                <h3>#Error 404</h3>
                <div className="endpoint mb-5">
                    <p> 404 | Not Found</p>
                </div>

                <h2 className='mt-5'>Request Header</h2>

                <Table bordered>
                    <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Required or Optional</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Authorization</td>
                            <td>Required</td>
                            <td>@Enter authorization information for a successful call.</td>
                        </tr>
                        <tr>
                            <td>Content-Type</td>
                            <td>Required</td>
                            <td>Defines the content type of the API session. Defines the content type of the API session. Valid values: application/json and application/xml.</td>
                        </tr>
                        <tr>
                            <td>Accept</td>
                            <td>Required</td>
                            <td>Accepts a DocId or other input as provided in the document list from the previous create or read job.</td>
                        </tr>
                        <tr>
                            <td>X-CSRF-Protection</td>
                            <td>Required for POST, UPDATE, and DELETE requests. Optional for GET requests.</td>
                            <td>Defines the content type of the API session. Accepts any non-null value. This helps to prevent Cross-Site Request Forgery vulnerabilities.</td>
                        </tr>
                    </tbody>
                </Table>







                {/* POST */}
                {/* <h2 className="section mt-5">POST Data Realisasi Anggaran</h2>
                <div className="endpoint mb-5" >
                    <p>
                        {' '}
                        <CopyToClipboard
                            text="https://sipolan.pemkomedan.go.id/realisasi-anggaran/api/sipolan?page=1"
                            onCopy={handleCopyClick}>

                            <Button variant="secondary">{isCopied ? 'Copied! ✔️' : 'Copy'}</Button>
                        </CopyToClipboard> https://sipolan.pemkomedan.go.id/realisasi-anggaran/api/sipolan?page=1
                    </p>
                </div> */}




            </div>
        </div>
    )
}

export default Home
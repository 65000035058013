import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import errors from '../assets/json/eror.JSON';
import success from '../assets/json/suces.JSON';
import poss from '../assets/json/post.JSON';
import putt from '../assets/json/put.JSON';
import dele from '../assets/json/del.JSON';
import '../style/Styles.css';
import { Table, Button } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function ApiDataMenu(props) {
  const [konten, setKonten] = useState([]);
  const [err, setErr] = useState([]);
  const [scs, setScs] = useState([]);
  const [pos, setPos] = useState([]);
  const [pput, setPput] = useState([]);
  const [del, setDel] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  let { men, apidata } = useParams();

  // const pantek = [
  //   {
  //     name: 'kamvret',
  //     endpoint: 'www.siarbola.com',
  //     method: 'get',
  //   },
  // ];

  // const kentut = [
  //   {
  //     name: 'kentut',
  //     endpoint: 'www.kulitsbola.com',
  //     method: 'post',
  //   },
  // ];

  // const show = () => {
  //   if (apidata === 'dataapi' || apidata === 'home') {
  //     return JSON.stringify(pantek);
  //   } else {
  //     return JSON.stringify(kentut);
  //   }
  // };

  const DelData = () => {
    fetch(dele)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setDel(json);
      });
  };

  const PutData = () => {
    fetch(putt)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setPput(json);
      });
  };

  const successData = () => {
    fetch(success)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setScs(json);
      });
  };

  const errorData = () => {
    fetch(errors)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setErr(json);
      });
  };

  const posData = () => {
    fetch(poss)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setPos(json);
      });
  };

  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    // If successful, update the isCopied state value
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        // let response = await axios.get(`http://localhost:5000/menu`);
        // const res = response.data.data;
        // console.log(res);
        // const ress = res.map((result) => result.path);

        // let resp = await axios.get(`http://localhost:5000/sub_menu`);
        let resp = await axios.get(`https://testing.metromatika.com/sub_menu`);
        const resss = resp.data.data;
        const respp = resss.map((res) => res.path_sub);
        // console.log(respp);

        // const r = ress.concat(respp);
        // console.log(r);
        // console.log(ress, 'yyy');

        respp.map(async (r) => {
          if (apidata === r) {
            // console.log(r, 'harus');
            // let respo = await axios.get(`http://localhost:5000/sub_menu/${apidata}`);
            let respo = await axios.get(`https://testing.metromatika.com/sub_menu/${apidata}`);
            console.log(respo.data.data.menu.path);
            men = respo.data.data.menu.path;
            setKonten(respo.data.data);

            // console.log(JSON.stringify(respo.data.data));
          }
        });
        //   if (apidata === response.data.path) {
        //     setKonten(response.data);
        //   }
      } catch (e) {
        console.log(e.message);
      }
    };

    getData();
    errorData();
    successData();
    posData();
    PutData();
    DelData();
  }, [men, apidata]);

  return (
    // <div className='row pembagi'>
    // <div className='col-sm-10 left'>
    <div>
      {/* <p>{konten}</p> */}
      <h1>{konten.name}</h1>
      <hr />
      <div>
        <h6 className="caption mt-2 mb-3">{konten.description}</h6>
        <h2 className="section">GET Data {konten.name}</h2>
        <div className="endpoint">
          <p>
            {' '}
            <CopyToClipboard text={konten.get_endpoint} onCopy={handleCopyClick}>
              <Button variant="secondary">{isCopied ? 'Copied! ✔️' : 'Copy'}</Button>
            </CopyToClipboard>{' '}
            {konten.get_endpoint}
          </p>
        </div>

        <h2 className="mt-5">Request Header</h2>
        <Table bordered>
          <thead>
            <tr>
              <th>Parameter</th>
              <th>Required or Optional</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Authorization</td>
              <td>Required</td>
              <td>@Enter authorization information for a successful call.</td>
            </tr>
            <tr>
              <td>Content-Type</td>
              <td>Required</td>
              <td>Defines the content type of the API session. Defines the content type of the API session. Valid values: application/json and application/xml.</td>
            </tr>
            <tr>
              <td>Accept</td>
              <td>Required</td>
              <td>Accepts a DocId or other input as provided in the document list from the previous create or read job.</td>
            </tr>
            <tr>
              <td>X-CSRF-Protection</td>
              <td>Required for POST, UPDATE, and DELETE requests. Optional for GET requests.</td>
              <td>Defines the content type of the API session. Accepts any non-null value. This helps to prevent Cross-Site Request Forgery vulnerabilities.</td>
            </tr>
          </tbody>
        </Table>

        <h2 className="section mt-5 mb-4">Respond Body</h2>
        <div className="mb-5">
          <h3>#Success 200</h3>
          <div className="endpoint">
            <pre className="output">{JSON.stringify(scs, null, 4)}</pre>
          </div>
        </div>

        {/* <div className="endpoint mb-5">
          <Button onClick={fetchData} variant="success">
            Output
          </Button>
          <pre className="output">{JSON.stringify(data, null, 3)}</pre>
        </div> */}

        <div className="mb-5">
          <h3>#Error 404</h3>
          <div className="endpoint">
            <pre className="output">{JSON.stringify(err, null, 4)}</pre>
          </div>
        </div>

        {/* POST */}
        <h2 className="section mt-5">POST Data {konten.name}</h2>
        <div className="endpoint mb-5">
          <p>
            {' '}
            <CopyToClipboard text={konten.get_endpoint} onCopy={handleCopyClick}>
              <Button variant="secondary">{isCopied ? 'Copied! ✔️' : 'Copy'}</Button>
            </CopyToClipboard>{' '}
            {konten.get_endpoint}
          </p>
        </div>

        <h2>Request Header</h2>
        <Table bordered>
          <thead>
            <tr>
              <th>Parameter</th>
              <th>Required or Optional</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Authorization</td>
              <td>Required</td>
              <td>@Enter authorization information for a successful call.</td>
            </tr>
            <tr>
              <td>Content-Type</td>
              <td>Required</td>
              <td>Defines the content type of the API session. Defines the content type of the API session. Valid values: application/json and application/xml.</td>
            </tr>
            <tr>
              <td>Accept</td>
              <td>Required</td>
              <td>Accepts a DocId or other input as provided in the document list from the previous create or read job.</td>
            </tr>
            <tr>
              <td>X-CSRF-Protection</td>
              <td>Required for POST, UPDATE, and DELETE requests. Optional for GET requests.</td>
              <td>Defines the content type of the API session. Accepts any non-null value. This helps to prevent Cross-Site Request Forgery vulnerabilities.</td>
            </tr>
          </tbody>
        </Table>

        <h2 className="section mt-5 mb-4">Respond Body</h2>
        <div className="mb-5">
          <h3>#Success 200</h3>
          <div className="endpoint">
            <pre className="output">{JSON.stringify(pos, null, 4)}</pre>
          </div>
        </div>

        <h3>#Error 404</h3>
        <div className="endpoint mb-5">
          <p> 404 | Not Found</p>
        </div>

        {/* PUT */}
        <h2 className="section mt-5">PUT Data {konten.name}</h2>
        <div className="endpoint mb-5">
          <p>
            {' '}
            <CopyToClipboard text={konten.get_endpoint} onCopy={handleCopyClick}>
              <Button variant="secondary">{isCopied ? 'Copied! ✔️' : 'Copy'}</Button>
            </CopyToClipboard>{' '}
            {konten.get_endpoint}
          </p>
        </div>

        <h2>Request Header</h2>
        <Table bordered>
          <thead>
            <tr>
              <th>Parameter</th>
              <th>Required or Optional</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Authorization</td>
              <td>Required</td>
              <td>@Enter authorization information for a successful call.</td>
            </tr>
            <tr>
              <td>Content-Type</td>
              <td>Required</td>
              <td>Defines the content type of the API session. Defines the content type of the API session. Valid values: application/json and application/xml.</td>
            </tr>
            <tr>
              <td>Accept</td>
              <td>Required</td>
              <td>Accepts a DocId or other input as provided in the document list from the previous create or read job.</td>
            </tr>
            <tr>
              <td>X-CSRF-Protection</td>
              <td>Required for POST, UPDATE, and DELETE requests. Optional for GET requests.</td>
              <td>Defines the content type of the API session. Accepts any non-null value. This helps to prevent Cross-Site Request Forgery vulnerabilities.</td>
            </tr>
          </tbody>
        </Table>

        <h2 className="section mt-5 mb-2">Respond Body</h2>
        <div className="mb-5">
          <h3>#Success 200</h3>
          <div className="endpoint">
            <pre className="output">{JSON.stringify(pput, null, 4)}</pre>
          </div>
        </div>

        <h3>#Error 404</h3>
        <div className="endpoint mb-5">
          <p> 404 | Not Found</p>
        </div>

        {/* DELETE */}
        <h2 className="section mt-5">DELETE Data {konten.name}</h2>
        <div className="endpoint mb-5">
          <p>
            {' '}
            <CopyToClipboard text={konten.get_endpoint} onCopy={handleCopyClick}>
              <Button variant="secondary">{isCopied ? 'Copied! ✔️' : 'Copy'}</Button>
            </CopyToClipboard>{' '}
            {konten.get_endpoint}
          </p>
        </div>

        <h2>Request Header</h2>
        <Table bordered>
          <thead>
            <tr>
              <th>Parameter</th>
              <th>Required or Optional</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Authorization</td>
              <td>Required</td>
              <td>@Enter authorization information for a successful call.</td>
            </tr>
            <tr>
              <td>Content-Type</td>
              <td>Required</td>
              <td>Defines the content type of the API session. Defines the content type of the API session. Valid values: application/json and application/xml.</td>
            </tr>
            <tr>
              <td>Accept</td>
              <td>Required</td>
              <td>Accepts a DocId or other input as provided in the document list from the previous create or read job.</td>
            </tr>
            <tr>
              <td>X-CSRF-Protection</td>
              <td>Required for POST, UPDATE, and DELETE requests. Optional for GET requests.</td>
              <td>Defines the content type of the API session. Accepts any non-null value. This helps to prevent Cross-Site Request Forgery vulnerabilities.</td>
            </tr>
          </tbody>
        </Table>

        <h2 className="section mt-5 mb-2">Respond Body</h2>
        <div className="mb-5">
          <h3>#Success 200</h3>
          <div className="endpoint">
            <pre className="output">{JSON.stringify(del, null, 4)}</pre>
          </div>
        </div>

        <h3>#Error 404</h3>
        <div className="endpoint">
          <p> 404 | Not Found</p>
        </div>
      </div>
    </div>
  );
}

import './App.css';
import SideMenu from './components/SideMenu';
// import SideRight from './components/SideRight';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';

//import { Row, Col } from 'react-bootstrap';

import Login from './auth/Login';
import LandingPage from './auth/LandingPage';
import Register from './auth/Register';
import ApiDataMenu from './page/ApiData_menu';
// import ApiDataSubMen from './page/ApiData_submenu';
import DataApi from './page/DataApi';
// import GempaBumi from './page/informasiBerita/GempaBumi';
import SuratAlquran from './page/informasiBerita/SuratAlquran';
// import JadwalSholat from './page/informasiBerita/JadwalSholat';
// import RealisasiAnggaran from './page/pemerintah/RealisasiAnggaran';
// import RekapitulasiRealisasiAnggaran from './page/pemerintah/RekapitulasiRealisasiAnggaran';
import Home from './page/Home';
// import CardContent from './components/CardContent';
// import PerkiraanCuaca from './page/informasiBerita/PerkiraanCuaca';
// import CardContent from './components/CardContent';
// import ApiData from './page/ApiData_submenu';

function App() {
  const [inactive, setInactive] = useState(false);
  const [token, setToken] = useState('');
  const [token2, setToken2] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('@token');
    if (token) {
      setToken(token);
    }

    const token2 = localStorage.getItem('@token2');
    if (token2) {
      setToken2(token2);
    }
  }, [token, token2]);
  return token ? (
    <div className="App">
      <Router>
        <SideMenu
          onCollapse={(inactive) => {
            console.log(inactive);
            setInactive(inactive);
          }}
        />

        <div className={`contain ${inactive ? 'inactive' : ''}`}>
          <Routes>
            <Route exact path="/:men/:apidata" element={<ApiDataMenu />} />
            <Route exact path="/dataapi" element={<DataApi />} />
            <Route exact path="/home" element={<Home />} />
            {/* <Route exact path="/kesehatan/rumahsakit" element={<RumahSakit />} />
            <Route exact path="/informasiberita/news" element={<JadwalSholat />} />
            <Route exact path="/informasiberita/gempa_bumi" element={<GempaBumi />} />
            <Route exact path="/informasiberita/suratalquran" element={<SuratAlquran />} />
            <Route exact path="/informasiberita/perkiraan_cuaca" element={<PerkiraanCuaca />} />
            <Route exact path="/pemerintah/realisasi_anggaran" element={<RealisasiAnggaran />} />
            <Route exact path="/pemerintah/rekapitulasi_realisasi_anggaran" element={<RekapitulasiRealisasiAnggaran />} /> */}
          </Routes>
        </div>
      </Router>
    </div>
  ) : token2 ? (
    <div className="App">
      <Router>
        <SideMenu
          onCollapse={(inactive) => {
            console.log(inactive);
            setInactive(inactive);
          }}
        />

        <div className={`contain ${inactive ? 'inactive' : ''}`}>
          <Routes>
            {/* <Route exact path="/:apidata" element={<ApiDataMenu />} /> */}
            {/* <Route exact path="/kesehatan/rumahsakit" element={<RumahSakit />} />
            <Route exact path="/content" element={<Content />} />
            <Route exact path="/informasiberita/news" element={<JadwalSholat />} /> */}
            <Route exact path="/informasiberita/suratalquran" element={<SuratAlquran />} />
            {/*<Route exact path="/pemerintah/realisasi_anggaran" element={<RealisasiAnggaran />} />
            <Route exact path="/pemerintah/rekapitulasi_realisasi_anggaran" element={<RekapitulasiRealisasiAnggaran />} /> */}
          </Routes>
        </div>
      </Router>
    </div>
  ) : (
    <div className="auth">
      <Router>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

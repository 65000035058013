import React, { useState } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import '../style/Register.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import logo from '../assets/images/logo.png';


const Register = () => {
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confPassword, setconfPassword] = useState('');
  const [errors, setErrors] = useState('');

  const navigate = useNavigate();
  const Auth = async (e) => {
    e.preventDefault();
    try {
      await axios
        // .post('http://149.28.129.12:3001/register', {
        .post('https://testing.metromatika.com/register', {
          username: username,
          name: name,
          email: email,
          password: password,
          confPassword: confPassword,
        })
        .then((res) => {
          console.log(res.data.message);
          if (res.status === 200) {
            Swal.fire({
              icon: 'success',
              title: 'PERINGATAN!',
              text: res.data.message,
            });
            navigate('/login');
            // window.location.reload();
          } else {
            console.log('Yhuuuhuuu');
          }
        });
    } catch (error) {
      // console.log(error)
      Swal.fire({
        icon: 'warning',
        title: 'PERINGATAN!',
        text: error.response.data.message,
      });
    }
  };
  return (
    <div className="container align-items-center p-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card p-4">
            <div className='text-center'>
              <img className='logo' src={logo} alt="Logo" />
            </div>
            <label className="title text-center">Register</label>
            <Form onSubmit={Auth}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Username</Form.Label>
                <Form.Control onChange={(e) => setUsername(e.target.value)} value={username} type="input" placeholder="Masukkan nama akun anda" />
                <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Nama Lengkap</Form.Label>
                <Form.Control onChange={(e) => setName(e.target.value)} value={name} type="input" placeholder="Masukkan nama lengkap anda" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email </Form.Label>
                <Form.Control onChange={(e) => setEmail(e.target.value)} value={email} type="input" placeholder="Masukkan nama email anda" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control onChange={(e) => setPassword(e.target.value)} value={password} type="password" placeholder="masukkan Password" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Konfirmasi Password</Form.Label>
                <Form.Control onChange={(e) => setconfPassword(e.target.value)} value={confPassword} type="password" placeholder="ulangi masukkan Password" />
              </Form.Group>

              <div className="text-center">
                <Button className="submit" variant="primary" type="submit">
                  Simpan
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
      {/* <Card className='register text-center'>
                <Card.Img variant="top" src="holder.js/100px180" />
                <Card.Body>
                    <Card.Title>Register</Card.Title>

                    <Button variant="primary">Simpan</Button>
                </Card.Body>
            </Card> */}
    </div>
  );
};

export default Register;

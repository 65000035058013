import Card from 'react-bootstrap/Card';

function CardContent(props) {
  const { content } = props;

  return (
    <Card>
      <Card.Header>Maaf Anda tidak memiliki hak akses</Card.Header>
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <h5> Maaf Anda tidak memiliki hak akses, Silahkan hubungi Admin atau Mendaftar pada Helpdesk Data Center Pemerintah Kota Medan </h5>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default CardContent;

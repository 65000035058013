import React from 'react';
import '../style/Styles.css';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const navigate = useNavigate();

  const login = () => {
    navigate('/login');
  };

  const docs = () => {
    localStorage.setItem('@token2', 'bdsjhbfdshjb');
    navigate('/pemerintah/rekapitulasi');
    window.location.reload();
  };
  return (
    <div className="landing-container">
      <div className="titles">
        <h1 className="titleLanding">APPLICATION PROGRAMMING INTERFACE</h1>
        <h1 className="landing-title">DOCUMENTATIONS PEMERINTAH KOTA MEDAN</h1>
      </div>
      <div>
        <h6 className="landing-Caption">Selamat Datang di Website Dokumentasi API Kota Medan</h6>
        <h6 className="landing-Caption">Website ini berfungsi sebagai alat bantu informasi dari Pengguna layanan API milik Satuan Kerja Pemerintah Kota Medan</h6>
      </div>
      <div className="text-center">
        <Button variant="primary landing-login" onClick={login}>
          <i class="bi bi-box-arrow-in-right m-1"> </i>
          <b>Login </b>
        </Button>
      </div>
      {/* <div className="text-center">
        <Button variant="outline-primary landing-docs" onClick={docs}>
          Docs
        </Button>
        <Button variant="primary landing-login" onClick={login}>
          Login
        </Button>
      </div> */}
    </div>
  );
};

export default LandingPage;

import React, { useEffect, useState } from 'react';
import { Form, Button, Modal, FloatingLabel } from 'react-bootstrap';
import '../style/Styles.css';
import HeaderContent from '../components/HeaderContent';
import CardContent from '../components/CardContent';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const DataApi = () => {
  const [dataGET, setDataGET] = useState(false);
  const [dataPOST, setDataPOST] = useState(false);
  const [dataPUT, setDataPUT] = useState(false);
  const [dataDELETE, setDataDELETE] = useState(false);

  const GETClose = () => setDataGET(false);
  const showGET = () => setDataGET(true);
  const POSTClose = () => setDataPOST(false);
  const showPOST = () => setDataPOST(true);
  const PUTClose = () => setDataPUT(false);
  const showPUT = () => setDataPUT(true);
  const DELETEClose = () => setDataDELETE(false);
  const showDELETE = () => setDataDELETE(true);

  const [data, setData] = useState([]);
  const [token, setToken] = useState('');

  const [name, setName] = useState('');
  const [path, setPath] = useState('');
  const [menu_id, setMenuId] = useState('');
  const [status_api, setStatusApi] = useState('');
  const [description, setDescription] = useState('');
  const [get_req_header, setGetReqHeader] = useState([]);
  const [get_description, setGetDescription] = useState('');
  // const [get_method, setGetMethod] = useState();
  const [get_endpoint, setGetEndpoint] = useState('');
  const [post_req_header, setPostReqHeader] = useState([]);
  const [post_description, setPostDescription] = useState('');
  // const [post_method, setPostMethod] = useState();
  const [post_endpoint, setPostEndpoint] = useState('');
  const [put_req_header, setPutReqHeader] = useState([]);
  const [put_description, setPutDescription] = useState('');
  // const [put_method, setPutMethod] = useState();
  const [put_endpoint, setPutEndpoint] = useState('');
  const [delete_req_header, setDeleteReqHeader] = useState([]);
  const [delete_description, setDeleteDescription] = useState('');
  // const [delete_method, setDeleteMethod] = useState();
  const [delete_endpoint, setDeleteEndpoint] = useState('');

  const navigate = useNavigate();
  const SaveData = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('@token');
    try {
      await axios
        .post(
          // 'http://localhost:5000/add_sub_menu',
          'https://testing.metromatika.com/add_sub_menu',
          {
            menu_id: menu_id,
            description: description,
            status_api: status_api,
            path: path,
            name: name,
            get_description: get_description,
            get_req_header: get_req_header.toString(),
            get_endpoint: get_endpoint,
            post_description: post_description,
            post_req_header: post_req_header.toString(),
            post_endpoint: post_endpoint,
            put_description: put_description,
            put_req_header: put_req_header.toString(),
            put_endpoint: put_endpoint,
            delete_description: delete_description,
            delete_req_header: delete_req_header.toString(),
            delete_endpoint: delete_endpoint,
          },
          { headers: { Authorization: `Bearer ${token}` } },
        )
        .then((res) => {
          console.log(res.data.message);
          if (res.status === 200) {
            Swal.fire({
              icon: 'success',
              title: 'PERINGATAN!',
              text: res.data.message,
            });
            navigate('/dataapi');
            window.location.reload();
          } else {
            console.log('Yhuuuhuuu');
          }
        });
    } catch (error) {
      Swal.fire({
        icon: 'warning',
        title: 'PERINGATAN!',
        text: error.response.data.message,
      });
    }
  };

  const ChangeGet = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    console.log(value, checked);
    if (checked) {
      setGetReqHeader([...get_req_header, value]);
    } else {
      setGetReqHeader(get_req_header.filter((e) => e !== value));
    }
  };

  const ChangePost = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setPostReqHeader([...post_req_header, value]);
    } else {
      setPostReqHeader(post_req_header.filter((e) => e !== value));
    }
  };

  const ChangePut = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    if (checked) {
      setPutReqHeader([...put_req_header, value]);
    } else {
      setPutReqHeader(put_req_header.filter((e) => e !== value));
    }
  };

  const ChangeDelete = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    if (checked) {
      setDeleteReqHeader([...delete_req_header, value]);
    } else {
      setDeleteReqHeader(delete_req_header.filter((e) => e !== value));
    }
  };

  useEffect(() => {
    const getData = async () => {
      const token = localStorage.getItem('@token');
      await axios
        // .get('http://localhost:5000/menu', {
        //   headers: { Authorization: `Bearer ${token}` },
        // })
        // .then((res) => {
        //   // console.log('wakwak', res.data.data);
        //   setData(res.data.data);
        // });
        .get('https://testing.metromatika.com/menu', {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          // console.log('wakwak', res.data.data);
          setData(res.data.data);
        });
    };

    const token = localStorage.getItem('@token');
    if (token) {
      setToken(token);
    }

    getData();
  }, [token]);

  return (
    <div>
      <h1>Data API</h1>
      <hr />

      {/* // <HeaderContent /> */}
      {token ? (
        <Form onSubmit={SaveData}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Nama Data</Form.Label>
            <Form.Control type="input" onChange={(e) => setName(e.target.value)} value={name} placeholder="Masukkan nama data" />
          </Form.Group>

          <Form.Label>Deskripsi </Form.Label>
          <FloatingLabel className="mb-3" controlId="floatingTextarea2" label="Deskripsi">
            <Form.Control as="textarea" onChange={(e) => setDescription(e.target.value)} value={description} placeholder="Leave a comment here" style={{ height: '100px' }} />
          </FloatingLabel>

          <Form.Label>Kategori Data</Form.Label>
          <Form.Select onChange={(e) => setMenuId(e.target.value)} value={menu_id} className="mb-3" aria-label="Default select example">
            <option disabled>Pilih Kategori data </option>
            {data.map((menu, index) => (
              <option value={menu.id} key={index}>
                {menu.name}
              </option>
            ))}
          </Form.Select>

          <Form.Group className="mb-3" controlId="pathApi">
            <Form.Label>Path</Form.Label>
            <Form.Control type="input" onChange={(e) => setPath(e.target.value)} value={path} placeholder="contoh : kesehatan/klinik" />
          </Form.Group>

          <div className="mb-2">
            <h1>{status_api}</h1>
            <input className="m-2" type="radio" onChange={(e) => setStatusApi(e.target.value)} value="Public" name="statusapi" />
            Public
            <input className="m-2" type="radio" onChange={(e) => setStatusApi(e.target.value)} value="Private" name="statusapi" />
            Private
          </div>

          <div className="Modal-Section">
            <Button variant="primary" onClick={showGET}>
              GET
            </Button>

            <Modal show={dataGET} onHide={GETClose}>
              <Modal.Header closeButton>
                <Modal.Title>METHOD GET</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="endpointApi">
                    <Form.Label>Endpoint Api</Form.Label>
                    <Form.Control type="input" onChange={(e) => setGetEndpoint(e.target.value)} value={get_endpoint} placeholder="contoh : http://localhost:3000/dataapi" autoFocus />
                  </Form.Group>
                  <label htmlFor=""> Select Headers: &nbsp;</label>
                  <br />
                  <input type="checkbox" name="headers" value="Authorization" onChange={ChangeGet} />
                  <label htmlFor="">&nbsp; Authorization</label>
                  &nbsp;
                  <br />
                  <input type="checkbox" name="headers" value="Content-Type" onChange={ChangeGet} />
                  <label htmlFor="">&nbsp; Content-Type</label>
                  &nbsp;
                  <br />
                  <input type="checkbox" name="headers" value="Accept" onChange={ChangeGet} />
                  <label htmlFor="">&nbsp; Accept</label>
                  &nbsp;
                  <br />
                  <input type="checkbox" name="headers" value="X-CSRF-Protection" onChange={ChangeGet} />
                  <label htmlFor="">&nbsp; X-CSRF-Protection</label>
                  <br />
                  <br />
                  <FloatingLabel controlId="floatingTextarea" label="Keterangan" className="mb-3">
                    <Form.Control as="textarea" onChange={(e) => setGetDescription(e.target.value)} value={get_description} placeholder="Leave a comment here" />
                  </FloatingLabel>
                </Form>
              </Modal.Body>
              <Modal.Footer className="text-center justify-content-center">
                <Button className="landing-login" variant="primary" onClick={GETClose}>
                  Simpan
                </Button>
              </Modal.Footer>
            </Modal>

            <Button variant="primary m-2" onClick={showPOST}>
              POST
            </Button>

            <Modal show={dataPOST} onHide={POSTClose}>
              <Modal.Header closeButton>
                <Modal.Title>METHOD POST</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="endpointApi">
                    <Form.Label>Endpoint Api</Form.Label>
                    <Form.Control type="input" onChange={(e) => setPostEndpoint(e.target.value)} value={post_endpoint} placeholder="contoh : http://localhost:3000/dataapi" autoFocus />
                  </Form.Group>
                  <label htmlFor=""> Select Headers: &nbsp;</label>
                  <br />
                  <input type="checkbox" name="headers" value="Authorization" onChange={ChangePost} />
                  <label htmlFor="">&nbsp; Authorization</label>
                  &nbsp;
                  <br />
                  <input type="checkbox" name="headers" value="Content-Type" onChange={ChangePost} />
                  <label htmlFor="">&nbsp; Content-Type</label>
                  &nbsp;
                  <br />
                  <input type="checkbox" name="headers" value="Accept" onChange={ChangePost} />
                  <label htmlFor="">&nbsp; Accept</label>
                  &nbsp;
                  <br />
                  <input type="checkbox" name="headers" value="X-CSRF-Protection" onChange={ChangePost} />
                  <label htmlFor="">&nbsp; X-CSRF-Protection</label>
                  <br />
                  <br />
                  <FloatingLabel controlId="floatingTextarea" label="Keterangan" className="mb-3">
                    <Form.Control as="textarea" onChange={(e) => setPostDescription(e.target.value)} value={post_description} placeholder="Leave a comment here" />
                  </FloatingLabel>
                </Form>
              </Modal.Body>
              <Modal.Footer className="text-center justify-content-center">
                <Button className="landing-login" variant="primary" onClick={POSTClose}>
                  Simpan
                </Button>
              </Modal.Footer>
            </Modal>

            <Button variant="primary" onClick={showPUT}>
              PUT
            </Button>

            <Modal show={dataPUT} onHide={PUTClose}>
              <Modal.Header closeButton>
                <Modal.Title>METHOD PUT</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="endpointApi">
                    <Form.Label>Endpoint Api</Form.Label>
                    <Form.Control type="input" onChange={(e) => setPutEndpoint(e.target.value)} value={put_endpoint} placeholder="contoh : http://localhost:3000/dataapi" autoFocus />
                  </Form.Group>
                  <label htmlFor=""> Select Headers: &nbsp;</label>
                  <br />
                  <input type="checkbox" name="headers" value="Authorization" onChange={ChangePut} />
                  <label htmlFor="">&nbsp; Authorization</label>
                  &nbsp;
                  <br />
                  <input type="checkbox" name="headers" value="Content-Type" onChange={ChangePut} />
                  <label htmlFor="">&nbsp; Content-Type</label>
                  &nbsp;
                  <br />
                  <input type="checkbox" name="headers" value="Accept" onChange={ChangePut} />
                  <label htmlFor="">&nbsp; Accept</label>
                  &nbsp;
                  <br />
                  <input type="checkbox" name="headers" value="X-CSRF-Protection" onChange={ChangePut} />
                  <label htmlFor="">&nbsp; X-CSRF-Protection</label>
                  <br />
                  <br />
                  <FloatingLabel controlId="floatingTextarea" label="Keterangan" className="mb-3">
                    <Form.Control as="textarea" onChange={(e) => setPutDescription(e.target.value)} value={put_description} placeholder="Leave a comment here" />
                  </FloatingLabel>
                </Form>
              </Modal.Body>
              <Modal.Footer className="text-center justify-content-center">
                <Button className="landing-login" variant="primary" onClick={PUTClose}>
                  Simpan
                </Button>
              </Modal.Footer>
            </Modal>

            <Button variant="primary m-2" onClick={showDELETE}>
              DELETE
            </Button>

            <Modal show={dataDELETE} onHide={DELETEClose}>
              <Modal.Header closeButton>
                <Modal.Title>METHOD DELETE</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="endpointApi">
                    <Form.Label>Endpoint Api</Form.Label>
                    <Form.Control type="input" onChange={(e) => setDeleteEndpoint(e.target.value)} value={delete_endpoint} placeholder="contoh : http://localhost:3000/dataapi" autoFocus />
                  </Form.Group>
                  <label htmlFor=""> Select Headers: &nbsp;</label>
                  <br />
                  <input type="checkbox" name="headers" value="Authorization" onChange={ChangeDelete} />
                  <label htmlFor="">&nbsp; Authorization</label>
                  &nbsp;
                  <br />
                  <input type="checkbox" name="headers" value="Content-Type" onChange={ChangeDelete} />
                  <label htmlFor="">&nbsp; Content-Type</label>
                  &nbsp;
                  <br />
                  <input type="checkbox" name="headers" value="Accept" onChange={ChangeDelete} />
                  <label htmlFor="">&nbsp; Accept</label>
                  &nbsp;
                  <br />
                  <input type="checkbox" name="headers" value="X-CSRF-Protection" onChange={ChangeDelete} />
                  <label htmlFor="">&nbsp; X-CSRF-Protection</label>
                  <br />
                  <br />
                  <FloatingLabel controlId="floatingTextarea" label="Keterangan" className="mb-3">
                    <Form.Control as="textarea" onChange={(e) => setDeleteDescription(e.target.value)} value={delete_description} placeholder="Leave a comment here" />
                  </FloatingLabel>
                </Form>
              </Modal.Body>
              <Modal.Footer className="text-center justify-content-center">
                <Button className="landing-login" variant="primary" onClick={DELETEClose}>
                  Simpan
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <div className="text-center mt-3">
            <Button className="landing-login" variant="primary" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      ) : (
        <CardContent />
      )}
    </div>
  );
};

export default DataApi;

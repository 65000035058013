import React, { useState, useEffect } from 'react';
import axios from 'axios';
import errors from '../../assets/json/eror.JSON';
import success from '../../assets/json/suratQuran.JSON';
import poss from '../../assets/json/post.JSON';
import putt from '../../assets/json/put.JSON';
import dele from '../../assets/json/del.JSON';
import '../../style/Styles.css';
import { Table, Button } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const resultPost = '{ msg : success }';
const resultPut = '{ msg : data berhasil di ubah }';
const resultDelete = '{ msg : data berhasil di hapus }';

export default function SuratAlquran() {
  const [data, setData] = useState();
  const [err, setErr] = useState([]);
  const [scs, setScs] = useState([]);
  const [pos, setPos] = useState([]);
  const [pput, setPput] = useState([]);
  const [del, setDel] = useState([]);
  const [token, setToken] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('@token');
    if (token) {
      setToken(token);
    }

    errorData();
    successData();
    posData();
    PutData();
    DelData();
  }, [token]);

  const fetchData = () => {
    fetch('https://sipolan.pemkomedan.go.id/realisasi-anggaran/api/sipolan/rekapitulasi')
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setData(json);
      });
  };

  const DelData = () => {
    fetch(dele)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setDel(json);
      });
  };

  const PutData = () => {
    fetch(putt)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setPput(json);
      });
  };

  const successData = () => {
    fetch(success)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setScs(json);
      });
  };

  const errorData = () => {
    fetch(errors)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setErr(json);
      });
  };

  const posData = () => {
    fetch(poss)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setPos(json);
      });
  };

  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    // If successful, update the isCopied state value
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <div>
      <h1>API Surat Alquran</h1>
      <hr />
      <div>
        <h6 className="caption mt-2 mb-3">Data API ini memuat data Surat yang ada dalam Alquran disertai penjelasan maknanya</h6>
        <h2 className="section">GET Data Surat Alquran</h2>
        <div className="endpoint">
          <p>
            {' '}
            <CopyToClipboard text="https://api.banghasan.com/quran/format/json/surat" onCopy={handleCopyClick}>
              <Button variant="secondary">{isCopied ? 'Copied! ✔️' : 'Copy'}</Button>
            </CopyToClipboard>{' '}
            https://api.banghasan.com/quran/format/json/surat
          </p>
        </div>

        <h2 className="mt-5">Request Header</h2>
        <Table bordered>
          <thead>
            <tr>
              <th>Parameter</th>
              <th>Required or Optional</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Authorization</td>
              <td>Required</td>
              <td>@Enter authorization information for a successful call.</td>
            </tr>
            <tr>
              <td>Content-Type</td>
              <td>Required</td>
              <td>Defines the content type of the API session. Defines the content type of the API session. Valid values: application/json and application/xml.</td>
            </tr>
            <tr>
              <td>Accept</td>
              <td>Required</td>
              <td>Accepts a DocId or other input as provided in the document list from the previous create or read job.</td>
            </tr>
            <tr>
              <td>X-CSRF-Protection</td>
              <td>Required for POST, UPDATE, and DELETE requests. Optional for GET requests.</td>
              <td>Defines the content type of the API session. Accepts any non-null value. This helps to prevent Cross-Site Request Forgery vulnerabilities.</td>
            </tr>
          </tbody>
        </Table>

        <h2 className="section mt-5 mb-4">Respond Body</h2>
        <div className="mb-5">
          <h3>#Success 200</h3>
          <div className="endpoint">
            <pre className="output">{JSON.stringify(scs, null, 4)}</pre>
          </div>
        </div>

        <div className="mb-5">
          <h3>#Error 404</h3>
          <div className="endpoint">
            <pre className="output">{JSON.stringify(err, null, 4)}</pre>
          </div>
        </div>

        {/* POST */}
        <h2 className="section mt-5">POST Data Surat Alquran</h2>
        <div className="endpoint mb-5">
          <p>
            {' '}
            <CopyToClipboard text="https://api.banghasan.com/quran/format/json/surat" onCopy={handleCopyClick}>
              <Button variant="secondary">{isCopied ? 'Copied! ✔️' : 'Copy'}</Button>
            </CopyToClipboard>{' '}
            https://api.banghasan.com/quran/format/json/surat
          </p>
        </div>

        <h2>Request Header</h2>
        <Table bordered>
          <thead>
            <tr>
              <th>Parameter</th>
              <th>Required or Optional</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Authorization</td>
              <td>Required</td>
              <td>@Enter authorization information for a successful call.</td>
            </tr>
            <tr>
              <td>Content-Type</td>
              <td>Required</td>
              <td>Defines the content type of the API session. Defines the content type of the API session. Valid values: application/json and application/xml.</td>
            </tr>
            <tr>
              <td>Accept</td>
              <td>Required</td>
              <td>Accepts a DocId or other input as provided in the document list from the previous create or read job.</td>
            </tr>
            <tr>
              <td>X-CSRF-Protection</td>
              <td>Required for POST, UPDATE, and DELETE requests. Optional for GET requests.</td>
              <td>Defines the content type of the API session. Accepts any non-null value. This helps to prevent Cross-Site Request Forgery vulnerabilities.</td>
            </tr>
          </tbody>
        </Table>

        <h2 className="section mt-5 mb-4">Respond Body</h2>
        <div className="mb-5">
          <h3>#Success 200</h3>
          <div className="endpoint">
            <pre className="output">{JSON.stringify(pos, null, 4)}</pre>
          </div>
        </div>

        <h3>#Error 404</h3>
        <div className="endpoint mb-5">
          <p> 404 | Not Found</p>
        </div>

        {/* PUT */}
        <h2 className="section mt-5">PUT Data Surat Alquran</h2>
        <div className="endpoint mb-5">
          <p>
            {' '}
            <CopyToClipboard text="https://api.banghasan.com/quran/format/json/surat" onCopy={handleCopyClick}>
              <Button variant="secondary">{isCopied ? 'Copied! ✔️' : 'Copy'}</Button>
            </CopyToClipboard>{' '}
            https://api.banghasan.com/quran/format/json/surat
          </p>
        </div>

        <h2>Request Header</h2>
        <Table bordered>
          <thead>
            <tr>
              <th>Parameter</th>
              <th>Required or Optional</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Authorization</td>
              <td>Required</td>
              <td>@Enter authorization information for a successful call.</td>
            </tr>
            <tr>
              <td>Content-Type</td>
              <td>Required</td>
              <td>Defines the content type of the API session. Defines the content type of the API session. Valid values: application/json and application/xml.</td>
            </tr>
            <tr>
              <td>Accept</td>
              <td>Required</td>
              <td>Accepts a DocId or other input as provided in the document list from the previous create or read job.</td>
            </tr>
            <tr>
              <td>X-CSRF-Protection</td>
              <td>Required for POST, UPDATE, and DELETE requests. Optional for GET requests.</td>
              <td>Defines the content type of the API session. Accepts any non-null value. This helps to prevent Cross-Site Request Forgery vulnerabilities.</td>
            </tr>
          </tbody>
        </Table>

        <h2 className="section mt-5 mb-2">Respond Body</h2>
        <div className="mb-5">
          <h3>#Success 200</h3>
          <div className="endpoint">
            <pre className="output">{JSON.stringify(pput, null, 4)}</pre>
          </div>
        </div>

        <h3>#Error 404</h3>
        <div className="endpoint mb-5">
          <p> 404 | Not Found</p>
        </div>

        {/* DELETE */}
        <h2 className="section mt-5">DELETE Data Surat Alquran</h2>
        <div className="endpoint mb-5">
          <p>
            {' '}
            <CopyToClipboard text="https://api.banghasan.com/quran/format/json/surat" onCopy={handleCopyClick}>
              <Button variant="secondary">{isCopied ? 'Copied! ✔️' : 'Copy'}</Button>
            </CopyToClipboard>{' '}
            https://api.banghasan.com/quran/format/json/surat
          </p>
        </div>

        <h2>Request Header</h2>
        <Table bordered>
          <thead>
            <tr>
              <th>Parameter</th>
              <th>Required or Optional</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Authorization</td>
              <td>Required</td>
              <td>@Enter authorization information for a successful call.</td>
            </tr>
            <tr>
              <td>Content-Type</td>
              <td>Required</td>
              <td>Defines the content type of the API session. Defines the content type of the API session. Valid values: application/json and application/xml.</td>
            </tr>
            <tr>
              <td>Accept</td>
              <td>Required</td>
              <td>Accepts a DocId or other input as provided in the document list from the previous create or read job.</td>
            </tr>
            <tr>
              <td>X-CSRF-Protection</td>
              <td>Required for POST, UPDATE, and DELETE requests. Optional for GET requests.</td>
              <td>Defines the content type of the API session. Accepts any non-null value. This helps to prevent Cross-Site Request Forgery vulnerabilities.</td>
            </tr>
          </tbody>
        </Table>

        <h2 className="section mt-5 mb-2">Respond Body</h2>
        <div className="mb-5">
          <h3>#Success 200</h3>
          <div className="endpoint">
            <pre className="output">{JSON.stringify(del, null, 4)}</pre>
          </div>
        </div>

        <h3>#Error 404</h3>
        <div className="endpoint">
          <p> 404 | Not Found</p>
        </div>
      </div>
    </div>
  );
}

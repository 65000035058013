import React, { useEffect, useState } from 'react';
import logo from '../assets/images/logo.png';
import user from '../assets/images/user.png';
import MenuItem from './MenuItem';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Dropdown, Button } from 'react-bootstrap';
import DropdownButton from 'react-bootstrap/DropdownButton';

// const menuItem = [
//   { name: 'pendahuluan', to: '/home', iconClassName: 'bi bi-houses' },
//   { name: 'Data API', to: '/dataapi', iconClassName: 'bi bi-database' },
//   {
//     name: 'Pemerintah',
//     to: '/pemerintah/rekapitulasi',
//     iconClassName: 'bi bi-buildings',
//   },
//   { name: 'Kesehatan', iconClassName: 'bi bi-capsule-pill' },
//   { name: 'Ekonomi', to: '/ekonomi', iconClassName: 'bi bi-cash-coin' },
//   { name: 'Fasilitas Umum', to: '/fasilitasumum', iconClassName: 'bi bi-pin-map-fill' },
//   { name: 'Informasi Berita', to: '/berita', iconClassName: 'bi bi-newspaper' },
// ];

export default function SideMenu(props) {
  const [inactive, setInactive] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [menu, setMenu] = useState([]);

  const [search, setSearch] = useState('');

  const { id } = useParams();

  const navigate = useNavigate();
  const Logout = async () => {
    localStorage.removeItem('@token');
    localStorage.removeItem('@token2');
    localStorage.removeItem('@id');
    try {
      await axios.post('https://testing.metromatika.com/logout');
      navigate('/');
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (inactive) {
      document.querySelectorAll('.sub-menu').forEach((el) => {
        el.classList.remove('active');
      });
    }

    const token = localStorage.getItem('@token');
    if (token) {
      setToken(token);
    }

    props.onCollapse(inactive);

    const getMenu = async () => {
      await axios.get(`https://testing.metromatika.com/menu`).then((res) => {
        setMenu(res.data.data);
      });
    };

    const getUserById = async () => {
      const token = localStorage.getItem('@token');
      const id = localStorage.getItem('@id');
      await axios
        .get(`https://testing.metromatika.com/admin/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log(res.data.name);
          setName(res.data.name);
          setEmail(res.data.email);
        });
    };

    getMenu();
    getUserById();
  }, [id, inactive, token, name]);

  return (
    <div className={`side-menu ${inactive ? 'inactive' : ''}`}>
      <div>
        <div className="top-section">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <h3 className="brand">SmartCity</h3>

          {/* <div onClick={() => setInactive(!inactive)} className="toggle-menu-btn">
            {inactive ? <i class="bi bi-arrow-right-square-fill"></i> : <i class="bi bi-arrow-left-square-fill"></i>}
          </div> */}
        </div>

        <div className="search-controller">
          <button className="search-btn">
            <i class="bi bi-search"></i>
          </button>

          <input
            type="text"
            placeholder="search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>

        <div className="divider" />
      </div>

      <div className="main-menu">
        <ul>
          {menu.filter((menuItem) => {
            if (search === '') {
              return menuItem;
            } else if (menuItem.name.toLowerCase().includes(search.toLowerCase())) {
              return menuItem;
            }
          }).map((menuItem, index) => (
            <MenuItem
              key={index}
              name={menuItem.name}
              to={menuItem.path}
              subMenus={menuItem.submenu || []}
              iconClassName={menuItem.icon}
              onClick={() => {
                if (inactive) {
                  setInactive(false);
                }
              }}
            />
          ))}

          {/* <li>
            <a className="menu-item">
              <div className="menu-icon">
                <i class="bi bi-speedometer2"></i>
              </div>
              <span>Dashboard</span>
            </a>
          </li>
          <MenuItem 
            name={"Content"}
            subMenus={[{name: "Courses" }, {name: "Videos"}]}
          />
          <li>
            <a className="menu-item">
              <div className="menu-icon">
                <i class="bi bi-speedometer2"></i>
              </div>
              <span>Design</span>
            </a>
          </li> */}
        </ul>
      </div>
      {/* </Scrollbar> */}

      {token ? (
        <div className="side-menu-footer">
          <div className="avatar">
            <img src={user} alt="user" />
          </div>
          <div className="user-info">
            <h5>{name.length > 20 ? `${name.substring(0, 20)}...` : name}</h5>
            <h5>{email.length > 19 ? `${email.substring(0, 19)}...` : email}</h5>
          </div>
          <div className="option-account">
            <DropdownButton id={`dropdown-button-drop-up-centered`} drop={'up'} variant="secondary">
              <Dropdown.Item onClick={Logout}>Logout</Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      ) : (
        <div className="side-menu-footer">
          <Button variant="secondary landing-login" onClick={Logout}>
            Keluar
          </Button>
        </div>
      )}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card, Button, Form, Image } from 'react-bootstrap';
import Swal from 'sweetalert2'
import axios from 'axios';
import '../style/Login.css';
import logo from '../assets/images/logo.png';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState('');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();

  const Auth = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://testing.metromatika.com/login', {
        username: username,
        password: password,
      })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem('@token', res.data.accessToken);
            localStorage.setItem('@id', res.data.adminId);
            navigate('/home');
            window.location.reload();
            // console.log('kamvrett', res);
          } else {
            console.log('Yhuuuhuuu');
          }
        });
    } catch (error) {
      console.log(error)
      setErrors(error.response.data.message);
      Swal.fire({
        icon: 'warning',
        title: 'PERINGATAN!',
        text: error.response.data.message,
        // footer: '<a href="">Why do I have this issue?</a>'
      })
    }
  };

  return (
    <div className="container bgLogin align-items-center p-5">
      <div className="row justify-content-center">
        <div className="col-md-5 bg">
          <div className="card p-5 bodyLogin">
            {/* {
              errors && (
                <div className='alert alert-danger'>
                  <p>{errors}</p>
                </div>
              )
            } */}
            <div className='text-center'>
              <img className='logo' src={logo} alt="Logo" />
            </div>

            <label className="title text-center mb-2">Login</label>
            <Form onSubmit={Auth}>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Username</Form.Label>
                <Form.Control onChange={(e) => setUsername(e.target.value)} value={username} type="input" placeholder="Masukkan nama akun/email anda" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control onChange={(e) => setPassword(e.target.value)} value={password} type="password" placeholder="masukkan Password" />
              </Form.Group>

              <div className="text-center mt-3">
                <Button className="submit" variant="primary" type="submit">
                  Masuk
                </Button>
              </div>

            </Form>
          </div>
        </div>
      </div>
    </div>

    // <div className="Auth-form-container">
    //   <form onSubmit={Auth} className="Auth-form">
    //     <div className="Auth-form-content">
    //       <h3 className="Auth-form-title">Sign In</h3>
    //       <h3 className="Auth-form-title">{msg}</h3>
    //       <div className="form-group mt-3">
    //         <label>Email address</label>
    //         {/* <input type="email" className="form-control mt-1" value={username} placeholder="Enter email" /> */}
    //         <input type="text" className="form-control mt-1" placeholder="Enter email" value={username} onChange={(e) => setUsername(e.target.value)} />
    //       </div>
    //       <div className="form-group mt-3">
    //         <label>Password</label>
    //         {/* <input type="password" className="form-control mt-1" value={password} placeholder="Enter password" /> */}
    //         <input type="password" className="form-control mt-1" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)} />
    //       </div>
    //       <div className="d-grid gap-2 mt-3">
    //         <button type="submit" className="btn btn-primary">
    //           Submit
    //         </button>
    //       </div>
    //       <p className="forgot-password text-right mt-2">
    //         <Link to={'/register'}>Daftar</Link>
    //       </p>
    //     </div>
    //   </form>
    // </div>

  );
};
export default Login;
